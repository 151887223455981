var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-element-loading',{attrs:{"active":_vm.appLoading,"is-full-screen":true,"background-color":"#FFFFFF","color":"#097392","spinner":"bar-fade-scale"}}),(_vm.ServerError)?_c('ServerError'):_vm._e(),_c('v-snackbar',{attrs:{"color":"#097392","right":"","timeout":_vm.timeout},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"text-left":"","align-self-center":""}},[_vm._v(_vm._s(_vm.msg))]),_c('v-flex',{attrs:{"text-right":""}},[_c('v-btn',{attrs:{"small":"","ripple":false,"text":""},on:{"click":function($event){_vm.showSnackBar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),(_vm.top)?_c('v-layout',{attrs:{"wrap":"","justify-start":""}},[(_vm.top.image)?_c('v-flex',{attrs:{"xs12":"","align-self-start":""}},[_c('v-img',{staticClass:"align-end",attrs:{"cover":"","height":"450px","src":_vm.mediaURL + _vm.top.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('ImageLoader')]},proxy:true}],null,false,958508573)})],1):_vm._e()],1):_vm._e(),_c('v-layout',{attrs:{"wrap":"","justify-start":"","pa-4":"","pa-sm-12":"","pa-xl-16":""}},[_c('v-flex',{attrs:{"xs12":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-between":""}},[_c('v-flex',{attrs:{"xs12":"","text-left":"","text-uppercase":""}},[_c('span',{staticClass:"mem"},[_vm._v("Donate Us")])]),_c('v-flex',{attrs:{"xs12":"","pt-2":""}},[_c('v-progress-linear',{attrs:{"value":"100","color":"#bc4639"}})],1)],1),_c('v-layout',{attrs:{"justify-start":"","wrap":"","pt-4":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md8":"","lg8":"","align-self-center":"","text-justify":"","text-left":"","pr-md-4":""}},[_c('span',{staticStyle:{"font-family":"TitLight","font-size":"25px","color":"black"},style:({
              'font-size':
                _vm.$vuetify.breakpoint.name == 'xs'
                  ? '14px'
                  : _vm.$vuetify.breakpoint.name == 'sm'
                  ? '20px'
                  : _vm.$vuetify.breakpoint.name == 'md'
                  ? '20px'
                  : _vm.$vuetify.breakpoint.name == 'lg'
                  ? '20px'
                  : '35px',
            })},[_vm._v(" Your financial support/donation is appreciated! "),_c('br'),_c('br'),_c('a',{staticStyle:{"color":"blue !important","border-bottom":"2px solid blue !important"},attrs:{"target":"_blank","download":"Donation Form","href":'./../../assets/donate.pdf'}},[_vm._v(" Click here to download Donation form pdf ")])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }